/* You can add global styles to this file, and also import other style files */

html, body {
    height: 100%;
}

body {
    margin: 0;
    background-color: #EFF1F7;
    ;
    font-family: 'Open Sans', "Helvetica Neue", sans-serif;
    --app-gradient-primary: linear-gradient(to right, #4776e6, #8e54e9);
    --app-gradient-primary-reverse: linear-gradient(to bottom, #4776e6, #8e54e9);
    /* --app-gradient-primary: linear-gradient(to right, #373b44, #4286f4);
    --app-gradient-primary-reverse: linear-gradient(to bottom, #373b44, #4286f4); */
    --app-gradient-primary-fallback: #373b44;
}

/* Variables */

app-count-card-tile {
    --big-count-size: 56px;
}

/* Overwrites */

button {
    outline: 0 !important;
}

.hide-box-shadow-expansion-panel {
    box-shadow: none !important;
}

/* Common App styles */

/* Buttons styles */

.app-primary-btn {
    color: white;
    background-color: #0058FF;
    border-radius: .9rem !important;
    width: 6rem;
}

/* Card like table container */

.card-style-table {
    background-color: white;
    border-radius: 1em;
    box-shadow: 1px 1px 8px #888888;
}

.card-style-table .report-filters-heading {
    font-size: 22px;
}

/* Gray table */

.table.app-rounded-gray-hd-table {
    border-collapse: separate;
    border-spacing: 0 1.1em;
}

/* Fix for material dropdown inside app-rounded-gray-hd-table*/

.table.app-rounded-gray-hd-table mat-form-field {
    border-collapse: collapse;
}

.table.app-rounded-gray-hd-table th {
    background-color: #EFF1F7;
}

.table.app-rounded-gray-hd-table th:first-child {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
}

.table.app-rounded-gray-hd-table th:last-child {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
}

.card-style {
    background-color: white;
    box-shadow: 1px 1px 8px #888888;
}

/* violation scr */

.violation-view-body .mat-form-field-flex {
    border-radius: 15px;
    max-height: 56px !important;
}

/* calendar */

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 3em !important;
    width: 35% !important;
    margin: 0px auto !important;
    margin-bottom: 3em !important;
}

.fc .fc-scrollgrid-sync-table {
    border-style: initial !important;
}

.fc-theme-standard .fc-scrollgrid {
    border: none !important;
}

.fc-theme-standard .fc-scrollgrid a {
    text-decoration: none !important;
    color: #000 !important;
}

.fc-theme-standard td, .fc-theme-standard th {
    border: none !important;
}

.fc .fc-prev-button, .fc .fc-next-button {
    border-radius: 50% !important;
    background: #5A607F 0% 0% no-repeat padding-box !important;
    padding: 2px !important;
}

.fc .fc-prev-button .fc-icon, .fc .fc-next-button .fc-icon {
    font-size: 2em !important;
    font-weight: bold !important;
}

.fc table tbody .fc-daygrid-day {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #707070 !important;
}

.fc .fc-daygrid-day-top {
    flex-direction: row !important;
}

/* tab style */

.tabDiv .mat-tab-label {
    border: 1px solid black;
    width: 186px;
}

.tabDiv .mat-tab-label-active {
    color: #fff !important;
    background: #0058FF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 27px #00000029 !important;
    border: 1px solid #0058ff !important;
    /* transform: matrix(1, 0, -1, 1, 24, 0); */
}

.tabDiv .mat-tab-header {
    border-bottom: none;
}

mat-tab-group.skewed-tabs .mat-tab-label {
    transform: skew(-20deg);
    transform-origin: bottom;
    opacity: 1;
    font-weight: bold;
    font-style: normal;
}

mat-tab-group.skewed-tabs .mat-tab-label-content {
    transform: skew(20deg);
}

.tabDiv .mat-ink-bar {
    display: none;
}

/* Material overrides */

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #32CD32;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #006400;
}

.mat-slide-toggle .mat-slide-toggle-bar {
    background-color: #e0455e;
}

.mat-slide-toggle .mat-slide-toggle-thumb {
    background-color: orangered;
}

.btn {
    outline: none !important;
}

.borderless td, .borderless th {
    border: none;
}

.mat-sort-header-container {
    justify-content: center;
}

.notification-area-style .mat-menu-panel {
    max-width: 437px !important;
    height: 523px !important;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-ffffff);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #00000047;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    filter: blur(undefined);
}

.success-snackbar {
    background-color: #139C13;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.danger-snackbar {
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.pointer{
    cursor:pointer;
}



.custom-toggle-disabled-view.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
    background-color: #006400;
}

.custom-toggle-disabled-view.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
    background-color: #32CD32;
}
